// import Vue from 'vue'

import { createRouter, createWebHistory } from "vue-router";
// import VueRouter from 'vue-router'

// Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "인트로",
    component: () => import("@/views/IntroPage.vue"),
    // component: () => import("@/views/Login/LoginPage.vue"),
  },

  // 회원가입
  {
    path: "/Membership",
    name: "회원가입",
    component: () => import("@/views/Login/Membership/Main.vue"),
  },
  {
    path: "/MemberQR",
    name: "회원가입QR",
    component: () => import("@/views/Login/Membership/MemberQR/Main.vue"),
  },
  {
    path: "/MembershipInfo",
    name: "회원가입등록",
    component: () => import("@/views/Login/Membership/MemberInfo/Main.vue"),
  },
  {
    path: "/MembershipSuccess",
    name: "회원가입완료",
    component: () => import("@/views/Login/Membership/SuccessMember/Main.vue"),
  },
  {
    path: "/MembershipFind",
    name: "비밀번호찾기",
    component: () => import("@/views/Login/Memberfind/Main.vue"),
  },
  {
    path: "/MembershipSuccess",
    name: "회원가입완료",
    component: () => import("@/views/Login/Membership/SuccessMember/Main.vue"),
  },
  {
    path: "/MemberAccountEdit",
    name: "회원정보수정",
    component: () => import("@/views/Account/MemberInfo/Main.vue"),
  },

  // 근로자
  {
    path: "/Worker",
    name: "근로자",
    component: () => import("@/views/Worker/WorkerMain/1_MainLayout.vue"),
    children: [
      {
        path: "/WorkerMain",
        name: "근로자메인",
        component: () => import("@/views/Worker/WorkerMain/WorkerMain.vue"),
      },
    ],
  },

  {
    path: "/WorkerContent",
    name: "근로자컨텐츠",
    component: () => import("@/views/Worker/WorkerContent/ContentLayout.vue"),
    children: [
      {
        path: "/WorkerMenu",
        name: "근로자메뉴",
        component: () => import("@/views/Worker/WorkerContent/Menu/MenuMain.vue"),
      },
      {
        path: "/WorkerBoard",
        name: "근로자게시판",
        component: () => import("@/views/Worker/WorkerContent/Board/BoardMain.vue"),
      },
      {
        path: "/WorkerInBoard/:Board",
        name: "근로자게시판내부",
        component: () => import("@/views/Worker/WorkerContent/Board/InBoard/BoardMain.vue"),
      },
      {
        path: "/WorkerEditor/:Board/:BoardId?",
        name: "근로자에디터",
        component: () => import("@/views/Worker/WorkerContent/Board/Editor/BoardMain.vue"),
      },
      {
        path: "/WorkerFile/:Board/:BoardId?",
        name: "근로자에디터파일",
        component: () => import("@/views/Worker/WorkerContent/Board/Editor/FileList/BoardMain.vue"),
      },

      // 전자 출석
      {
        path: "/WorkerAttendance",
        name: "근로자전자출석",
        component: () => import("@/views/Worker/WorkerContent/Attendance/Main.vue"),
      },
      {
        path: "/WorkerAttendanceInfo",
        name: "근로자전자출석정보",
        component: () => import("@/views/Worker/WorkerContent/Attendance/AttendanceInfo/Main.vue"),
      },
      // 근태
      {
        path: "/WorkerCommute",
        name: "근로자근태현황",
        component: () => import("@/views/Worker/WorkerContent/Commute/Main.vue"),
      },
      // 스케줄러
      {
        path: "/WorkerScheduler",
        name: "근로자일정",
        component: () => import("@/views/Worker/WorkerContent/Scheduler/SchedulerMain.vue"),
      },
      {
        path: "/WorkerSchedulerMore/:Date",
        name: "근로자일정더보기",
        component: () => import("@/views/Worker/WorkerContent/Scheduler/Morepage/Main.vue"),
      },
      {
        path: "/TestMenu",
        name: "임시메뉴",
        component: () => import("@/views/Worker/WorkerContent/TestMenu/TestMain.vue"),
      },
      {
        path: "/WorkerPush",
        name: "푸시메세지",
        component: () => import("@/views/Worker/WorkerContent/Push/PushMain.vue"),
      },
    ],
  },

  // 담당자
  {
    path: "/Manager",
    name: "담당자",
    component: () => import("@/views/Manager/ManagerMain/1_MainLayout.vue"),
    children: [
      {
        path: "/ManagerMain",
        name: "담당자메인",
        component: () => import("@/views/Manager/ManagerMain/ManagerMain.vue"),
      },
    ],
  },

  {
    path: "/ManagerContent",
    name: "담당자컨텐츠",
    component: () => import("@/views/Manager/ManagerContent/ContentLayout.vue"),
    children: [
      // 결재
      {
        path: "/ManagerApproval",
        name: "담당자결재",
        component: () => import("@/views/Manager/ManagerContent/Approval/Main.vue"),
      },
      {
        path: "/ManagerApprovalInfo/:Status",
        name: "담당자결재정보",
        component: () => import("@/views/Manager/ManagerContent/Approval/ApprovalInfo/Main.vue"),
      },
      {
        path: "/approvalFolder",
        name: "ManagerapprovalFolder",
        component: () => import("@/views/Approval/ApprovalMain.vue"),
      },
      {
        path: "/approvalProgress",
        name: "ManagerapprovalProgress",
        component: () => import("@/views/Approval/ApprovalProgress.vue"),
      },
      {
        path: "/approvalLine",
        name: "ManagerapprovalLine",
        component: () => import("@/views/Approval/ApprovalLine.vue"),
      },
      {
        path: "/approvalReason",
        name: "ManagerapprovalReason",
        component: () => import("@/views/Approval/ApprovalReason.vue"),
      },
      // 메뉴
      {
        path: "/ManagerAlarm",
        name: "담당자알림",
        component: () => import("@/views/Manager/ManagerContent/Alarm/Main.vue"),
      },
      {
        path: "/ManagerMenu",
        name: "담당자메뉴",
        component: () => import("@/views/Manager/ManagerContent/Menu/MenuMain.vue"),
      },
      // 내업무들
      {
        path: "/ManagerMyWork",
        name: "담당자내업무",
        component: () => import("@/views/Manager/ManagerContent/MyWork/WorkMain.vue"),
      },

      // 안전보건목표 추진 계획
      {
        path: "/ManageSafetyHealth",
        name: "담당자안전보건목표",
        component: () => import("@/views/Manager/ManagerContent/MyWork/1_SafetyHealth/Main.vue"),
      },
      {
        path: "/ManageSafetyHealthEditor/:id/:edit",
        name: "담당자안전보건목표편집",
        component: () => import("@/views/Manager/ManagerContent/MyWork/1_SafetyHealth/EditDoc/Main.vue"),
      },
      {
        path: "/ManageSafetyHealthView/:id/:edit",
        name: "담당자안전보건목표보기",
        component: () => import("@/views/Manager/ManagerContent/MyWork/1_SafetyHealth/ViewDoc/Main.vue"),
      },

      // 위험성평가
      {
        path: "/ManagerDanger",
        name: "ManagerDanger",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/1_DangerList.vue"),
      },
      {
        path: "/ManagerDangerList1",
        name: "ManagerDangerList1",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/1_Meeting/1_MeetingList.vue"),
      },
      {
        path: "/ManagerDangerMeetingList",
        name: "ManagerDangerMeetingList",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/1_Meeting/2_MeetingMain.vue"),
      },
      {
        path: "/ManagerDangerMeetingEditor",
        name: "ManagerDangerMeetingEditor",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/1_Meeting/3_MeetingEditor.vue"),
      },
      {
        path: "/ManagerDangerMeetingAttendance",
        name: "ManagerDangerMeetingAttendance",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/1_Meeting/4_MeetingAttendance.vue"),
      },
      {
        path: "/ManagerDangerList2",
        name: "ManagerDangerList2",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/2_Education/1_EducationList.vue"),
      },
      {
        path: "/ManagerDangerEducationList/:handleType",
        name: "ManagerDangerEducationList",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/2_Education/2_EducationSelectList.vue"),
      },
      {
        path: "/ManagerDangerEducationEditor/:handleType/:editType",
        name: "ManagerDangerEducationEditor",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/2_Education/3_EducationEditor.vue"),
      },
      {
        path: "/ManagerDangerEducationAttendance",
        name: "ManagerDangerEducationAttendance",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/2_Education/4_EducationAttendance.vue"),
      },
      {
        path: "/ManagerDangerEducationPhotoEditor",
        name: "ManagerDangerEducationPhotoEditor",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/2_Education/5_EducationPhotoEditor.vue"),
      },
      {
        path: "/ManagerDangerEducationContent",
        name: "ManagerDangerEducationContent",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/2_Education/6_EducationContent.vue"),
      },
      // {
      //   path: "/ManagerDangerList3",
      //   name: "ManagerDangerList3",
      //   component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/3_Progress/1_ProgressList.vue"),
      // },
      {
        path: "/ManagerDangerList3",
        name: "ManagerDangerList3",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/3_Progress/1_ProgressList.vue"),
      },
      {
        path: "/ManagerDangerProgressEditor/:id/:edit",
        name: "ManagerDangerProgressEditor",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/3_Progress/2_Editor.vue"),
      },
      {
        path: "/ManagerDangerProgressEditorPhoto/:id/:edit/:index/:order?",
        name: "ManagerDangerProgressEditorPhoto",
        component: () => import("@/views/Manager/ManagerContent/MyWork/2_Danger/3_Progress/3_PhotoEditor.vue"),
      },
      // 교육훈련
      {
        path: "/ManagerEdu",
        name: "담당자교육훈련",
        component: () => import("@/views/Manager/ManagerContent/MyWork/3_Edu/EduMain.vue"),
      },
      {
        path: "/ManagerEduScheList/:type",
        name: "담당자예정교육리스트",
        component: () => import("@/views/Manager/ManagerContent/MyWork/3_Edu/EduInfo/Main.vue"),
      },
      {
        path: "/ManagerEduInfo/:type/:id",
        name: "담당자예정교육정보",
        component: () => import("@/views/Manager/ManagerContent/MyWork/3_Edu/EduInfo/ScheInner/Main.vue"),
      },
      {
        path: "/ManagerEduAttendance/:type?/:id?",
        name: "담당자출석부",
        component: () => import("@/views/Manager/ManagerContent/MyWork/3_Edu/EduInfo/Attendance/Main.vue"),
      },
      {
        path: "/ManagerEduPhoto/:type?/:id?",
        name: "담당자사진첨부",
        component: () => import("@/views/Manager/ManagerContent/MyWork/3_Edu/EduInfo/PhotoEditor/Main.vue"),
      },

      // 안전점검 회신
      {
        path: "/ManageSiteSafety",
        name: "담당자안전점검회신",
        component: () => import("@/views/Manager/ManagerContent/MyWork/4_SiteSafety/Main.vue"),
      },
      {
        path: "/ManageSiteSafetyEditor/:id/:edit",
        name: "담당자안전점검회신편집",
        component: () => import("@/views/Manager/ManagerContent/MyWork/4_SiteSafety/EditDoc/Main.vue"),
      },
      {
        path: "/ManageSiteSafetyView/:id/:edit",
        name: "담당자안전점검회신보기",
        component: () => import("@/views/Manager/ManagerContent/MyWork/4_SiteSafety/ViewDoc/Main.vue"),
      },

      // 게시판
      {
        path: "/ManagerBoard",
        name: "담당자게시판",
        component: () => import("@/views/Manager/ManagerContent/Board/BoardMain.vue"),
      },
      {
        path: "/ManagerBoardList/:Board",
        name: "담당자게시판내부",
        component: () => import("@/views/Manager/ManagerContent/Board/BoardList/BoardMain.vue"),
      },
      {
        path: "/ManagerEditor/:Board/:BoardId?",
        name: "담당자에디터",
        component: () => import("@/views/Manager/ManagerContent/Board/Editor/BoardMain.vue"),
      },
      {
        path: "/ManagerFile/:Board/:BoardId?",
        name: "담당자에디터파일",
        component: () => import("@/views/Manager/ManagerContent/Board/Editor/FileList/BoardMain.vue"),
      },
      // 스케줄러
      {
        path: "/ManagerScheduler",
        name: "담당자일정",
        component: () => import("@/views/Manager/ManagerContent/Scheduler/SchedulerMain.vue"),
      },
      {
        path: "/ManagerSchedulerMore/:Date",
        name: "담당자일정더보기",
        component: () => import("@/views/Manager/ManagerContent/Scheduler/Morepage/Main.vue"),
      },
      // 전자 출석
      {
        path: "/ManagerAttendance",
        name: "담당자전자출석",
        component: () => import("@/views/Manager/ManagerContent/Attendance/Main.vue"),
      },
      {
        path: "/ManagerAttendanceInfo",
        name: "담당자전자출석정보",
        component: () => import("@/views/Manager/ManagerContent/Attendance/AttendanceInfo/Main.vue"),
      },
      // 근태
      {
        path: "/ManagerCommute",
        name: "담당자근태현황",
        component: () => import("@/views/Manager/ManagerContent/Commute/Main.vue"),
      },
    ],
  },

  // 관리자
  {
    path: "/Admin",
    name: "관리자",
    component: () => import("@/views/Admin/AdminMain/1_MainLayout.vue"),
    children: [
      {
        path: "/AdminMain",
        name: "관리자메인",
        component: () => import("@/views/Admin/AdminMain/AdminMain.vue"),
      },
    ],
  },
  {
    path: "/AdminContent",
    name: "관리자컨텐츠",
    component: () => import("@/views/Admin/AdminContent/ContentLayout.vue"),
    children: [
      // 결재
      {
        path: "/AdminApproval",
        name: "관리자결재",
        component: () => import("@/views/Admin/AdminContent/Approval/Main.vue"),
      },
      {
        path: "/AdminApprovalInfo/:Status",
        name: "관리자결재정보",
        component: () => import("@/views/Admin/AdminContent/Approval/ApprovalInfo/Main.vue"),
      },
      {
        path: "/approvalFolder",
        name: "AdminapprovalFolder",
        component: () => import("@/views/Approval/ApprovalMain.vue"),
      },
      {
        path: "/approvalProgress",
        name: "AdminapprovalProgress",
        component: () => import("@/views/Approval/ApprovalProgress.vue"),
      },
      {
        path: "/approvalLine",
        name: "AdminapprovalLine",
        component: () => import("@/views/Approval/ApprovalLine.vue"),
      },
      {
        path: "/approvalReason",
        name: "AdminapprovalReason",
        component: () => import("@/views/Approval/ApprovalReason.vue"),
      },

      {
        path: "/AdminMenu",
        name: "관리자메뉴",
        component: () => import("@/views/Admin/AdminContent/Menu/MenuMain.vue"),
      },

      // 근태
      {
        path: "/AdminCommute",
        name: "관리자근태현황",
        component: () => import("@/views/Admin/AdminContent/Commute/Main.vue"),
      },
      {
        path: "/AdminCommuteInfo",
        name: "관리자근태관리",
        component: () => import("@/views/Admin/AdminContent/Commute/MonthCommute/Main"),
      },

      // 내업무들
      {
        path: "/AdminMyWork",
        name: "관리자내업무",
        component: () => import("@/views/Admin/AdminContent/MyWork/WorkMain.vue"),
      },

      // 안전보건목표 중점업무
      {
        path: "/AdminSafetyHealth",
        name: "관리자안전보건목표",
        component: () => import("@/views/Admin/AdminContent/MyWork/1_SafetyHealthy/Main.vue"),
      },
      {
        path: "/AdminSafetyHealthEditor/:id/:edit",
        name: "관리자안전보건목표편집",
        component: () => import("@/views/Admin/AdminContent/MyWork/1_SafetyHealthy/EditDoc/Main.vue"),
      },
      {
        path: "/AdminSafetyHealthView/:id/:edit",
        name: "관리자안전보건목표보기",
        component: () => import("@/views/Admin/AdminContent/MyWork/1_SafetyHealthy/ViewDoc/Main.vue"),
      },

      // 위험성평가
      {
        path: "/AdminDanger",
        name: "AdminDanger",
        component: () => import("@/views/Admin/AdminContent/MyWork/2_Danger/1_DangerList.vue"),
      },
      {
        path: "/AdminDangerList1",
        name: "AdminDangerList1",
        component: () => import("@/views/Admin/AdminContent/MyWork/2_Danger/1_Meeting/1_MeetingList.vue"),
      },
      {
        path: "/AdminDangerMeetingList",
        name: "AdminDangerMeetingList",
        component: () => import("@/views/Admin/AdminContent/MyWork/2_Danger/1_Meeting/2_MeetingMain.vue"),
      },
      {
        path: "/AdminDangerMeetingEditor",
        name: "AdminDangerMeetingEditor",
        component: () => import("@/views/Admin/AdminContent/MyWork/2_Danger/1_Meeting/3_MeetingEditor.vue"),
      },
      {
        path: "/AdminDangerMeetingAttendance",
        name: "AdminDangerMeetingAttendance",
        component: () => import("@/views/Admin/AdminContent/MyWork/2_Danger/1_Meeting/4_MeetingAttendance.vue"),
      },

      // 교육훈련
      {
        path: "/AdminEdu",
        name: "관리자교육훈련",
        component: () => import("@/views/Admin/AdminContent/MyWork/3_Edu/EduMain.vue"),
      },
      {
        path: "/AdminEduScheList/:type",
        name: "관리자예정교육리스트",
        component: () => import("@/views/Admin/AdminContent/MyWork/3_Edu/EduInfo/Main.vue"),
      },
      {
        path: "/AdminEduInfo/:type/:id",
        name: "관리자예정교육정보",
        component: () => import("@/views/Admin/AdminContent/MyWork/3_Edu/EduInfo/ScheInner/Main.vue"),
      },
      {
        path: "/AdminEduAttendance/:type?/:id?",
        name: "관리자출석부",
        component: () => import("@/views/Admin/AdminContent/MyWork/3_Edu/EduInfo/Attendance/Main.vue"),
      },
      {
        path: "/AdminEduPhoto/:type?/:id?",
        name: "관리자사진첨부",
        component: () => import("@/views/Admin/AdminContent/MyWork/3_Edu/EduInfo/PhotoEditor/Main.vue"),
      },

      // 게시판
      {
        path: "/AdminBoard",
        name: "관리자게시판",
        component: () => import("@/views/Admin/AdminContent/Board/BoardMain.vue"),
      },
      {
        path: "/AdminBoardList/:Board/:FieldId?",
        name: "관리자게시판내부",
        component: () => import("@/views/Admin/AdminContent/Board/BoardList/BoardMain.vue"),
      },
      {
        path: "/AdminFieldList/:Board/:FieldId?",
        name: "현장리스트",
        component: () => import("@/views/Admin/AdminContent/Board/FieldList/BoardMain.vue"),
      },
      {
        path: "/AdminEditor/:Board/:BoardId?/:FieldId?",
        name: "관리자에디터",
        component: () => import("@/views/Admin/AdminContent/Board/Editor/BoardMain.vue"),
      },
      {
        path: "/AdminFile/:Board/:BoardId?/:FieldId?",
        name: "관리자에디터파일",
        component: () => import("@/views/Admin/AdminContent/Board/Editor/FileList/BoardMain.vue"),
      },
      // 스케줄러
      {
        path: "/AdminScheduler",
        name: "관리자일정",
        component: () => import("@/views/Admin/AdminContent/Scheduler/SchedulerMain.vue"),
      },
      {
        path: "/AdminSchedulerMore/:Date",
        name: "관리자일정더보기",
        component: () => import("@/views/Admin/AdminContent/Scheduler/Morepage/Main.vue"),
      },

      // 전자 출석
      {
        path: "/AdminAttendance",
        name: "관리자전자출석",
        component: () => import("@/views/Admin/AdminContent/Attendance/Main.vue"),
      },
      {
        path: "/AdminAttendanceInfo",
        name: "관리자전자출석정보",
        component: () => import("@/views/Admin/AdminContent/Attendance/AttendanceInfo/Main.vue"),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
    hidden: true,
  },
  {
    path: "/ComponentEditor/:Board/:editorTitle/:editorColor/:saveText?/:BoardId?",
    name: "공용에디터",
    component: () => import("@/views/components/CompEditor.vue"),
  },
];

// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
