import request from "../index";

const commutesApiBaseUrl = "/commutes";

// 출석
export function commutesStartRequest(code) {
  return request({
    url: `${commutesApiBaseUrl}/qrcheckin`,
    method: "post",
    data: {
      qrCommuteId: code.qrCommuteId,
      qrCode:code.qrCode
    },
  });
}


export function commutesInfoRequest() {
  return request({
    url: `${commutesApiBaseUrl}/nowcommtuestatus`,
    method: "get",
  });
}



export function commutesWorkInfoRequest() {
  return request({
    url: `${commutesApiBaseUrl}/nowworkedtotalinfo`,
    method: "get",
  });
}


export function commutesEndRequest(code) {
  return request({
    url: `${commutesApiBaseUrl}/qrcheckout`,
    method: "post",
    data: {
      qrCommuteId: code.qrCommuteId,
      qrCode:code.qrCode
    },
  });
}

// 년월 모바일 근태 목록
export function commutesMyWorkedSummery(searchOption) {
  return request({
    url: `${commutesApiBaseUrl}/my-worked-summery`,
    method: "get",
    params: {
      searchDateOption: searchOption.dateOption,
      searchStartDate: searchOption.startDate,
      searchEndDate: searchOption.endDate
    },
  });
}

