
import createPersistedState from 'vuex-persistedstate';
// import router from '@/router'
import { getLoginedMemberRequest } from '@/assets/api/member-check.js';
import { commutesStartRequest, commutesEndRequest } from '@/assets/api/commutes/commutes-api.js'
import { createStore } from 'vuex';
export default createStore({
  state: {
    currentTenant: {
      subdomain: null
    },
    loginMember: {
      member: {
        memberId:null,
        name: null,
        level:null,
        adminLevel:null,
        positionName:null,
        rankName:null,
        status:null,
        hasApprovalPrivilege:null
      },
      field: {
        fieldId:null,
        fieldName:null,
        headName:null,
        type:null
      }
    },
    loginToken: {
      accessToken : null,
      refreshToken: null
    },
    commuteStatus:false,
    commuteCode: null,
    editorText: null,
    Register: {
      fieldId: null,
      fieldName: null
    }
  },
  getters: {
    getMemberData: state => state.loginMember

  },
  mutations: {
    SET_COMMUTE_STATUS(state, status) {
      state.commuteStatus = status;
    },


    SET_COMMUTE_CODE(state, code) {
      state.commuteCode = code;
    },
    SET_LOGIN_MEMBER(state, name) {
      state.loginMember.member.name = name;
    },
    SET_LOGIN_MEMBERID(state, memberId) {
      state.loginMember.member.memberId = memberId;
    },
    SET_LOGIN_LEVEL(state, level) {
      state.loginMember.member.level = level;
    },
    SET_LOGIN_ADMINLEVEL(state, adminLevel) {
      state.loginMember.member.adminLevel = adminLevel;
    },
    SET_LOGIN_POSITIONNAME(state, positionName) {
      state.loginMember.member.positionName = positionName;
    },
    SET_LOGIN_RANKNAME(state, rankName) {
      state.loginMember.member.rankName = rankName;
    },
    SET_LOGIN_STATUS(state, status) {
      state.loginMember.member.status = status;
    },
    SET_LOGIN_APPROVAL(state, approval) {
      state.loginMember.member.hasApprovalPrivilege = approval;
    },




    SET_LOGIN_FIELDID(state, fieldId) {
      state.loginMember.field.fieldId = fieldId;
    },
    SET_LOGIN_FIELD_NAME(state, fieldName) {
      state.loginMember.field.fieldName = fieldName;
    },
    SET_LOGIN_FIELD_HEADNAME(state, headName) {
      state.loginMember.field.headName = headName;
    },
    SET_LOGIN_FIELD_TYPE(state, fieldtype) {
      state.loginMember.field.type = fieldtype;
    },
  },
  actions: {
    async FETCH_LOGIN_MEMBER(context) {
      try {
        const response = await getLoginedMemberRequest();
        // let isLogined = false; // 로그인 여부

        const memberID = response.data.member.memberId;
        const memberName = response.data.member.name;
        const Level = response.data.member.level;
        const adminLevel = response.data.member.adminLevel;
        const positionName = response.data.member.positionName;
        const rankName = response.data.member.rankName;
        const status = response.data.member.status;
        const approval = response.data.member.hasApprovalPrivilege;

        const fieldID = response.data.field.fieldId;
        const fieldName = response.data.field.name;
        const headName = response.data.field.headName;
        const fieldType = response.data.field.type;
        
      // if (memberName && status !== 'INACTIVE') {
      //     isLogined = true;
      //   }

      //   if (isLogined) {
      //     if (fieldType === 'FIELD' && Level === 'LEVEL1') {
      //       router.push({ name: '근로자메인' });
      //     } else if (fieldType === 'FIELD' && Level !== 'LEVEL1') {
      //       router.push({ name: '담당자메인' });
      //     } else if (fieldType === 'HEAD') {
      //       router.push({ path: '/AdminMain' });
      //     }
      //   } else {
      //     alert('비정상적인 접근입니다.');
      //   }

        // 본사: true, 현장: false
        
        context.commit("SET_LOGIN_MEMBER", memberName);
        context.commit("SET_LOGIN_MEMBERID", memberID);
        context.commit("SET_LOGIN_LEVEL", Level);
        context.commit("SET_LOGIN_ADMINLEVEL", adminLevel);
        context.commit("SET_LOGIN_POSITIONNAME", positionName);
        context.commit("SET_LOGIN_RANKNAME", rankName);
        context.commit("SET_LOGIN_STATUS", status);
        context.commit("SET_LOGIN_APPROVAL", approval);


        context.commit("SET_LOGIN_FIELDID", fieldID);
        context.commit("SET_LOGIN_FIELD_NAME", fieldName);
        context.commit("SET_LOGIN_FIELD_HEADNAME", headName);
        context.commit("SET_LOGIN_FIELD_TYPE", fieldType);
        
      } catch (error) {
        console.log(error);
        alert(error.message)
        context.commit("SET_LOGIN_MEMBER", null);
        context.commit("SET_LOGIN_MEMBERID", null);
        context.commit("SET_LOGIN_LEVEL", null);
        context.commit("SET_LOGIN_ADMINLEVEL", null);
        context.commit("SET_LOGIN_POSITIONNAME", null);
        context.commit("SET_LOGIN_RANKNAME", null);
        context.commit("SET_LOGIN_STATUS", null);
        context.commit("SET_LOGIN_APPROVAL", null);


        context.commit("SET_LOGIN_FIELDID", null);
        context.commit("SET_LOGIN_FIELD_NAME", null);
        context.commit("SET_LOGIN_FIELD_HEADNAME", null);
        context.commit("SET_LOGIN_FIELD_TYPE", null);
      }
    },
    setCode({ commit }, code) {
      commit('SET_CODE', code);
    },
    async FETCH_COMMUTES_MEMBER(context,codeData) {
      try {
        console.log(`output->context`,context)
        const parts = codeData.split('|');
        if (parts.length === 2) {
          let Data = {
            qrCommuteId: parts[0],
            qrCode: parts[1]
          }
          const res = await commutesStartRequest(Data);
          alert(res.message)
          window.location.reload()
        }
      } catch(error) {
        alert(error.message);
      }
    },
    async FETCH_LEAVE_MEMBER(context,codeData) {
      try {
        console.log(`output->context`,context)
        const parts = codeData.split('|');
        if (parts.length === 2) {
          let Data = {
            qrCommuteId: parts[0],
            qrCode: parts[1]
          }
        const res = await commutesEndRequest(Data);
        alert(res.message)
        window.location.reload()
        }
      } catch(error) {

        alert(error.message);
      }
    },
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ["loginMember",
      'editorText',
      'currentTenant'
    ],
      
    })
  ]
})
