import axios from 'axios'
import { encrypt } from '@/utils/encrypto-util'
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  return null;
}
// request interceptor
service.interceptors.request.use(
  config => {
    const encrpytRequireEndPoints = [
      {url: '/members/login', method: 'post'},
      {url: '/members/on-mobile', method: 'post'},
      {url: '/members/*', method: 'put'},
      {url: '/members/leave-self', method: 'delete'}
    ]
    const needsEncryption = encrpytRequireEndPoints.some(endpoint => {
      const urlMatch = new RegExp(`^${endpoint.url.replace('*', '.*')}$`).test(config.url);
      return urlMatch && endpoint.method.toLowerCase() === config.method.toLowerCase();
    });
    
    if (needsEncryption) {
      const requestData = config.data;
      const encrpyData = encrypt(requestData);

      config.data = encrpyData;
      config.headers['Content-Type'] = 'text/plain';
    }
    
    if (getCookie("access_token") === null) {
      //
    } else {
      if (config.url != "/members/login") {
        const accessToken = getCookie("access_token");
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    // console.log(`output->config`,config)
  
    // config.headers.Authorization = `Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhZG1pbiIsImlhdCI6MTcxMjgyMjk0OSwiZXhwIjoxNzEyOTA5MzQ5fQ.UwdV_kNVxCIMqMi65n9pcB8J1PwfQBFB4rJT2QCMrio`
      
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const responseData = response.data
    return responseData
  },
  error => {
    const errorResponse = error.response.data
    return Promise.reject(errorResponse)
  }
)

export default service
