import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { logoutRequest } from '@/assets/api/member-check.js';
import './permission.js';

import '@progress/kendo-ui';
import './kendo-ui-license'; // kendo ui 라이센스 파일
import '@progress/kendo-theme-default/dist/all.css';
import "./styles/fonts.scss";
import "./styles/base_styles.scss";
import "./styles/comm_bar.scss";
import "./styles/comm_card.scss";
import "./styles/comm_button.scss";
import "./styles/comm_form.scss";
import "./styles/comm_styles.scss";
import "./styles/comm_title_style.scss";
import "./styles/Layout/Layout.css";
import "./styles/Layout/MainpageLayout.css";
import "./styles/Layout/BoardpageLayout.css";
import "./styles/Layout/MenupageLayout.css";
import "./styles/Layout/MyWorkpageLayout.css";
import "./styles/Layout/QuickmenuLayout.css";

const app = createApp(App);

app.config.globalProperties.resultAction = async function (type, data) {
  if (type === "AUTHENTICATION") {
    const authenticationData = JSON.parse(data);
    // token
    if (authenticationData) {
      const tokenList = authenticationData.token;
      document.cookie = "access_token=" + encodeURIComponent(tokenList.accessToken) + "; path=/;";
      document.cookie = "refresh_token=" + encodeURIComponent(tokenList.refreshToken) + "; path=/;";

      await store.dispatch("FETCH_LOGIN_MEMBER");
      let isLogined = false; // 로그인 여부
      const loginMemberName = store.state.loginMember.member.name;
      const FieldType = store.state.loginMember.field.type;
      const LoginLevel = store.state.loginMember.member.level
      const LoginStatus = store.state.loginMember.member.status

      if (loginMemberName && LoginStatus != 'INACTIVE') {
          isLogined = true;
      }
      if (isLogined) {
        if (FieldType === 'FIELD' && LoginLevel === 'LEVEL1') {
          router.replace({ name: '근로자메인' });
        } else if (FieldType === 'FIELD' && LoginLevel !== 'LEVEL1') {
          router.replace({ name: '담당자메인' });
        } else if (FieldType === 'HEAD') {
          router.replace({ path: '/AdminMain' });
        }
      } else {
        alert("비정상적인 접근입니다.");
        await logoutRequest()
        document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        var userAgent = navigator.userAgent.toLowerCase()
        if (userAgent.indexOf('android') > -1) {
          window.truesafe.requestAction("LOGOUT",null)
        } else if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1 || userAgent.indexOf('ipod') > -1) {
          window.webkit.messageHandlers.requestAction.postMessage("LOGOUT",null)
        }
        return;
      }
    }
  } 
  // else if (type === "COMMUTE_CODE") {
  //   const codeData = JSON.parse(data);
  //   store.dispatch('FETCH_COMMUTES_MEMBER',codeData.code)
  // } else if (type === "COMMUTE_LEAVE_CODE") {
  //   const codeData = JSON.parse(data);
  //   store.dispatch('FETCH_LEAVE_MEMBER',codeData.code)
  // }
};


store.dispatch("FETCH_LOGIN_MEMBER");


app.use(router);
app.use(store);

app.mount("#app");

createApp(App)
  .use(store)
  .use(router)
window.resultAction = app.config.globalProperties.resultAction;