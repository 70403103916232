import request from "./index";

const memberApiBaseUrl = "/members";

// 회원확인
export function getLoginedMemberRequest() {
  return request({
    url: `${memberApiBaseUrl}/logined`,
    method: "get",
  });
}

export function logoutRequest() {
  return request({
    url: `${memberApiBaseUrl}/logout`,
    method: 'post'
  })
}
export function loginRequest() {
  return request({
    url: `${memberApiBaseUrl}/login`,
    method: 'post',
    data :{
       id : 'user1',
       password : 'user1'
    }
  })
}